import { ActionProps } from '~/components/Actions/props';
import IconLink from '~/icons/Link';
import IconMessageCaptions from '~/icons/MessageCaptions';
// import IconMessageCaptions from '~/icons/MessageCaptions';
import IconNote from '~/icons/Note';

const useNotes = ({
  espaceId,
  pathname,
}: {
  espaceId: string;
  pathname: string;
}): (ActionProps | ActionProps[])[] | undefined => {
  const isActive = (to: string, strict?: boolean): string | undefined => {
    if (strict) {
      return `/espaces/${espaceId}/notes${to}` === pathname
        ? 'is-active'
        : undefined;
    }

    return pathname.slice(0, `/espaces/${espaceId}/notes${to}`.length) ===
      `/espaces/${espaceId}/notes${to}`
      ? 'is-active'
      : undefined;
  };

  return [
    {
      className: isActive(``, true),
      icon: IconNote,
      label: 'Notes',
      to: `/espaces/${espaceId}/notes`,
    },
    {
      className: isActive(`/extracts`),
      icon: IconLink,
      label: 'URLs Extraites',
      to: `/espaces/${espaceId}/notes/extracts`,
    },
    {
      className: isActive(`/notebook`),
      icon: IconMessageCaptions,
      label: 'Carnet de notes',
      to: `/espaces/${espaceId}/notes/notebook`,
    },
    // {
    //   className: isActive(`/thoughts`),
    //   icon: IconMessageCaptions,
    //   label: 'Pensées capturées',
    //   to: `/espaces/${espaceId}/notes/thoughts`,
    // },
  ];
};

export default useNotes;
