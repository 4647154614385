import { DocumentType, ExtractType } from '@innedit/innedit-type';
import { ExtractData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Media from '~/components/Media';
import { ListItemProps } from '~/containers/Espace/List/props';
import nextPhoto from '~/images/next-photo.svg';

import ListItem from './index';

const ListItemExtract: FC<ListItemProps<ExtractType, ExtractData>> = ({
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<ExtractType>>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const description =
    doc.description && doc.description.length > 250
      ? `${doc.description.slice(0, 250)}...`
      : doc.description;

  let media;

  if (doc.thumbnails && doc.thumbnails.length > 0) {
    [media] = doc.thumbnails;
  }

  const [, , domain] = doc.href.split('/');

  return (
    <ListItem
      className={className}
      description={description}
      displayActionId={false}
      doc={doc}
      icon={
        media ? (
          <Media
            media={media}
            options={{ crop: 'max', height: 80, width: 80 }}
          />
        ) : (
          <figure className="aspect-ratio aspect-ratio--square">
            <img
              alt="Chargement"
              className="aspect-ratio__content"
              src={nextPhoto}
            />
          </figure>
        )
      }
      index={index}
      label={doc.label}
      onClick={onClick}
      subLabel={domain}
    />
  );
};

export default ListItemExtract;
